import React, { useMemo } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
  FilterRow,
  Selection,
  Export,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import useFirestore from "../../hooks/useFirestore";
import { useLocation } from "react-router";

// abujaPostEncounter2023

const ReportTable = ({ location }) => {
  const { search } = useLocation();

  const isForeign = search === "?delegate=foreign";

  const { docs, isLoading } = useFirestore(
    location === "Abuja"
      ? "globalMissionsConference2023"
      : isForeign
      ? "campmeeting2025ForeignDelegates"
      : "campmeeting2025",
    location === "Abuja" ? undefined : "location",
    location === "Abuja" ? undefined : location
  );

  const spinner = () => {
    return (
      <div className='mb-40 h-screen'>
        <LoadPanel
          visible={true}
          showIndicator={true}
          closeOnOutsideClick={false}
        />
      </div>
    );
  };

  const title = useMemo(() => {
    switch (location) {
      case "Abuja":
        return "Global Missions Conference 2023 Registration Record";

      case "Enugu":
        return isForeign
          ? "Camp Meeting 2025 Foreign Delegates Registration Record"
          : "Enugu Camp Meeting 2025 Registration Record";

      case "Lagos":
        return isForeign
          ? "Camp Meeting 2025 Foreign Delegates Registration Record"
          : "Lagos Camp Meeting 2025 Registration Record";
      default:
        return "Registration Record";
    }
  }, [location, isForeign]);

  return (
    <div className='container px-4 mx-auto mb-12'>
      <h5 className='font-semibold'>{title}</h5>
      {isLoading ? (
        spinner()
      ) : (
        <>
          {isForeign ? (
            <DataGrid
              dataSource={docs}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              rowAlternationEnabled={true}
              hoverStateEnabled={true}
              wordWrapEnabled={true}
              columnHidingEnabled={true}
            >
              <Selection
                mode='multiple'
                selectAllMode='page'
                showCheckBoxesMode='always'
              />

              <HeaderFilter allowSearch={true} visible={true} />

              <FilterRow visible={true} applyFilter='auto' />

              <SearchPanel
                visible={true}
                searchVisibleColumnsOnly={true}
                highlightSearchText={true}
              />
              <ColumnChooser enabled={true} mode='select' allowSearch={true} />

              <Export
                enabled={true}
                excelWrapTextEnabled={true}
                fileName='Data'
                allowExportSelectedData={true}
              />

              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20]}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Column caption='Full Name' dataField='name' />
              <Column caption='Email' dataField='email' />
              <Column caption='Arrival Date' dataField='arrivalDate' />
              <Column caption='Arrival Time' dataField='arrivalTime' />
              <Column caption='Airline' dataField='airline' />
              <Column caption='Location' dataField='location' />
              <Column caption='Assistance Needed' dataField='assistance' />
              <Column caption='Payment verified' dataField='verified' />
            </DataGrid>
          ) : (
            <DataGrid
              dataSource={docs}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              rowAlternationEnabled={true}
              hoverStateEnabled={true}
              wordWrapEnabled={true}
              columnHidingEnabled={true}
            >
              <Selection
                mode='multiple'
                selectAllMode='page'
                showCheckBoxesMode='always'
              />

              <HeaderFilter allowSearch={true} visible={true} />

              <FilterRow visible={true} applyFilter='auto' />

              <SearchPanel
                visible={true}
                searchVisibleColumnsOnly={true}
                highlightSearchText={true}
              />
              <ColumnChooser enabled={true} mode='select' allowSearch={true} />

              <Export
                enabled={true}
                excelWrapTextEnabled={true}
                fileName='Data'
                allowExportSelectedData={true}
              />

              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20]}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Column caption='First Name' dataField='firstName' />
              <Column caption='Last Name' dataField='lastName' />
              <Column caption='Phone' dataField='phone' />
              <Column caption='Email' dataField='email' />
              <Column caption='Registration class' dataField='class' />
              <Column caption='Amount' dataField='cost' />
              <Column caption='Payment verified' dataField='verified' />
              <Column caption='Location' dataField='location' />
              <Summary
                recalculateWhileEditing={true}
                texts={{ sum: "Total = {0}" }}
              >
                <TotalItem column='cost' summaryType='sum' />
              </Summary>
            </DataGrid>
          )}
        </>
      )}
    </div>
  );
};

export default ReportTable;
