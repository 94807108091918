import React, { Suspense, useState } from "react";
import { Route, Switch, Redirect } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ReactNotifications } from "react-notifications-component";
import Landing from "./views/Landing";
import TestimonyPage from "./views/TestimonyPage";
import PrayerPage from "./views/PrayerPage";
import GivePage from "./views/GivePage";
import RegisterPage from "./views/RegisterPage";
import AboutPage from "./views/AboutPage";
import ConatctPage from "./views/ConatctPage";
import BankPage from "./views/BankPage";
import PrivacyPage from "./views/PrivacyPage";
import LivePage from "./views/LivePage";
// import LagosRegister from "./views/LagosRegister";
// import EnuguRegistraion from "./views/EnuguRegistraion";
import LagosAdminPage from "./views/LagosAdminPage";
import EnuguAdminPage from "./views/EnuguAdminPage";
import AdminPage from "./views/AdminPage";
import HonourPage from "./views/HonourPage";
import PartnershipPage from "./views/PartnershipPage";
import ProjectsPage from "./views/ProjectsPage";
import OfferingsPage from "./views/OfferingsPage";
import MissionsPage from "./views/MissionsPage";
import OnlineFormPage from "./views/OnlineFormPage";
import AccomodationPage from "./views/AccomodationPage";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.css";
import "./App.css";
import PaymentVerification from "./views/PaymentVerification";
import PaymentConfrimation from "./views/PaymentConfirmation";
import LiveStream from "./views/LiveStream";
import BuildingProjectPage from "./views/BuildingProjectPage";
import BuildingProjects from "./views/BuildingProjects";
import SingleProjectPage from "./views/SingleProjectPage";
import { LoadPanel } from "devextreme-react/load-panel";
// import AbujaRegister from "./views/AbujaRegister";
// import AbujaAdminPage from "./views/AbujaAdminPage";

// const Landing = React.lazy(() => import("./views/Landing"));
// const TestimonyPage = React.lazy(() => import("./views/TestimonyPage"));
// const PrayerPage = React.lazy(() => import("./views/PrayerPage"));
// const GivePage = React.lazy(() => import("./views/GivePage"));
// const RegisterPage = React.lazy(() => import("./views/RegisterPage"));
// const AboutPage = React.lazy(() => import("./views/AboutPage"));
// const ConatctPage = React.lazy(() => import("./views/ConatctPage"));
// const BankPage = React.lazy(() => import("./views/BankPage"));
// const PrivacyPage = React.lazy(() => import("./views/PrivacyPage"));
// const LivePage = React.lazy(() => import("./views/LivePage"));
const LagosRegister = React.lazy(() => import("./views/LagosRegister"));
const EnuguRegistraion = React.lazy(() => import("./views/EnuguRegistraion"));
// const LagosAdminPage = React.lazy(() => import("./views/LagosAdminPage"));
// const EnuguAdminPage = React.lazy(() => import("./views/EnuguAdminPage"));
// // const AdminPage = React.lazy(() => import("./views/AboutPage"));
// const HonourPage = React.lazy(() => import("./views/HonourPage"));
// const PartnershipPage = React.lazy(() => import("./views/PartnershipPage"));
// const ProjectsPage = React.lazy(() => import("./views/ProjectsPage"));
// const OfferingsPage = React.lazy(() => import("./views/OfferingsPage"));
// const MissionsPage = React.lazy(() => import("./views/MissionsPage"));
// const OnlineFormPage = React.lazy(() => import("./views/OnlineFormPage"));
// const AccomodationPage = React.lazy(() => import("./views/AccomodationPage"));

export const MySwal = withReactContent(Swal);

function App() {
  const [pathname, setPathname] = useState("/");
  const [isAuthenticated, setIsAuthenticated] = useState({
    lagos: false,
    enugu: false,
    abuja: false,
    admin: false,
    testify: false,
    prayer: false,
  });

  const setCurrentLocation = (location: string) => {
    setPathname(location);
  };

  const logout = () => {
    setIsAuthenticated({
      lagos: false,
      enugu: false,
      admin: false,
      testify: false,
      prayer: false,
      abuja: false,
    });
  };

  const setAuthState = (
    state: boolean,
    location: "lagos" | "enugu" | "admin" | "testify" | "prayer" | "abuja"
  ) => {
    setIsAuthenticated({ ...isAuthenticated, [location]: state });
  };

  return (
    <Suspense
      fallback={
        <div className='mb-40 h-screen'>
          <LoadPanel
            visible={true}
            showIndicator={true}
            closeOnOutsideClick={false}
          />
        </div>
      }
    >
      <div
        className={
          pathname === "/gmtv"
            ? "App bg-black overflow-x-hidden"
            : "App overflow-x-hidden"
        }
      >
        <ReactNotifications isMobile />
        {/* <Navbar pathname={pathname} /> */}

        <Switch>
          <Route
            path='/'
            exact
            render={() => (
              <Landing
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />
          <Route
            path='/testimony'
            exact
            render={() => (
              <TestimonyPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/church-project'
            exact
            render={() => (
              <BuildingProjectPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />
          <Route
            path='/church-building-projects'
            exact
            render={() => (
              <BuildingProjects
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/church-building-projects/:id'
            exact
            component={SingleProjectPage}
          />
          <Route
            path='/prayer'
            exact
            render={() => (
              <PrayerPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/give'
            exact
            render={() => (
              <GivePage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/register'
            exact
            render={() => (
              <RegisterPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/accomodation'
            exact
            render={() => (
              <AccomodationPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/about'
            exact
            render={() => (
              <AboutPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/contact'
            exact
            render={() => (
              <ConatctPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/missions'
            exact
            render={() => (
              <MissionsPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/online-forms'
            exact
            render={() => (
              <OnlineFormPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/give/bank-transfer'
            exact
            render={() => (
              <BankPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/give/honour'
            exact
            render={() => (
              <HonourPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/give/partnership'
            exact
            render={() => (
              <PartnershipPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/give/projects'
            exact
            render={() => (
              <ProjectsPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/give/offerings'
            exact
            render={() => (
              <OfferingsPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/verify/:reference'
            exact
            component={PaymentVerification}
          />

          <Route path='/confirmation' exact component={PaymentConfrimation} />

          <Route
            path='/privacy'
            exact
            render={() => (
              <PrivacyPage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/gmtv'
            exact
            render={() => (
              <LivePage
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/livestream'
            exact
            render={() => (
              <LiveStream
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/register/lagos'
            exact
            render={() => (
              <LagosRegister
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          />
          {/* 
        <Route
          path='/register/abuja'
          exact
          render={() => (
            <AbujaRegister
              setCurrentLocation={setCurrentLocation}
              pathname={pathname}
            />
          )}
        /> */}
          {/* 
          <Route
            path='/register/enugu'
            exact
            render={() => (
              <EnuguRegistraion
                setCurrentLocation={setCurrentLocation}
                pathname={pathname}
              />
            )}
          /> */}

          <Route
            path='/admin/lagos'
            exact
            render={() => (
              <LagosAdminPage
                setCurrentLocation={setCurrentLocation}
                setAuthState={setAuthState}
                isAuthenticated={isAuthenticated.lagos}
                logout={logout}
                pathname={pathname}
              />
            )}
          />

          <Route
            path='/admin/enugu'
            exact
            render={() => (
              <EnuguAdminPage
                setCurrentLocation={setCurrentLocation}
                setAuthState={setAuthState}
                isAuthenticated={isAuthenticated.enugu}
                logout={logout}
                pathname={pathname}
              />
            )}
          />

          {/* <Route
          path='/admin/abuja'
          exact
          render={() => (
            <AbujaAdminPage
              setCurrentLocation={setCurrentLocation}
              setAuthState={setAuthState}
              isAuthenticated={isAuthenticated.abuja}
              logout={logout}
              pathname={pathname}
            />
          )}
        /> */}

          <Route
            path='/admin'
            render={() => (
              <AdminPage
                setCurrentLocation={setCurrentLocation}
                isAuthenticated={{
                  admin: isAuthenticated.admin,
                  prayer: isAuthenticated.prayer,
                  testify: isAuthenticated.testify,
                }}
                setAuthState={setAuthState}
                logout={logout}
                pathname={pathname}
              />
            )}
          />

          <Redirect from='*' to='/' />
        </Switch>

        {/* <Footer /> */}
      </div>
    </Suspense>
  );
}

export default App;
