import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footers/Footer.js";
import Testimony from "../components/Testimony/Testimony";
import PrayerRequest from "../components/Prayer/PrayerRequest";
import Giving from "../components/Give/Giving";
import Register from "../components/Registration/Register";
import Cta from "../components/CTA/Cta";
// import Birthday from "../components/birthday/Birthday";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import sanity from "../lib/sanity";
import BuildingProject from "../components/BuildingProject/BuildingProject";

const query = `
*[_type == "landingDisplay"] {
  _id,
  title,
  "webLandscape": webLandscape.asset->url,
   "webPotrait": webPotrait.asset->url,
}[0...50]

`;

interface Props {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const Landing: React.FC<Props> = ({ setCurrentLocation, pathname }) => {
  const [images, setImages] = useState<
    { _id: string; webLandscape: string; webPotrait: string; title: string }[]
  >([]);

  useEffect(() => {
    setCurrentLocation("/");
  }, []);

  useEffect(() => {
    const fetchImgs = async () => {
      try {
        const data = await sanity.fetch(query);
        setImages(data);
      } catch (error) {}
    };

    fetchImgs();
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 640px)",
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
  };
  return (
    <>
      <Navbar pathname={pathname} />
      {/* bg-blue-900  */}
      <main>
        {/* <div className="relative pt-16 pb-32 flex content-center items-center justify-center  landing h-screen landing-head">
          <div className="absolute top-0 w-full h-full bg-center bg-cover main-bg">
            <span
              id="blackOverlay"
              className="w-full  h-full absolute "
              // opacity-10 bg-black
            ></span>
          </div>
        </div> */}
        <div className='w-full pt-16 pb-32'>
          <Slider {...settings}>
            {images.map((e) => (
              <div style={{ width: "100%" }} key={e._id}>
                <img
                  src={isDesktopOrLaptop ? e.webLandscape : e.webPotrait}
                  alt={e.title}
                  className='w-full'
                />
              </div>
            ))}
          </Slider>
        </div>

        {/* <Birthday /> */}
        <Cta isTop={true} />

        {/* style="padding:56.25% 0 0 0;position:relative;" */}

        {/* style="position:absolute;top:0;left:0;width:100%;height:100%;" */}

        <Register />
        <BuildingProject />
        <Giving />
        <Testimony />
        <PrayerRequest />
        <div className='lg:mb-20 mb-6 lg:mt-0 mt-24'>
          <Cta isTop={false} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Landing;
